import { useQuery, UseQueryOptions } from "react-query";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables>(
  endpoint: string,
  requestInit: RequestInit,
  query: string,
  variables?: TVariables
) {
  return async (): Promise<TData> => {
    const res = await fetch(endpoint, {
      method: "POST",
      ...requestInit,
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0];

      throw new Error(message);
    }

    return json.data;
  };
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Datetime: any;
  JSON: any;
  JSONObject: any;
};

export type Lesson = {
  __typename?: "Lesson";
  id?: Maybe<Scalars["ID"]>;
  charges?: Maybe<Scalars["JSON"]>;
  title?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  metadata?: Maybe<LessonMetadata>;
  rand?: Maybe<Scalars["String"]>;
  active?: Maybe<Scalars["Boolean"]>;
  createdAt?: Maybe<Scalars["Datetime"]>;
  updatedAt?: Maybe<Scalars["Datetime"]>;
};

export type LessonMetadata = {
  __typename?: "LessonMetadata";
  mediaIds?: Maybe<Scalars["JSON"]>;
  type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  main?: Maybe<Scalars["String"]>;
  start_date?: Maybe<Scalars["String"]>;
  days?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["String"]>;
  time_other?: Maybe<Scalars["String"]>;
  people?: Maybe<Scalars["JSON"]>;
  howHeard?: Maybe<Scalars["String"]>;
  level?: Maybe<Scalars["String"]>;
  timelength?: Maybe<Scalars["String"]>;
  adults?: Maybe<Scalars["Int"]>;
  kids?: Maybe<Scalars["Int"]>;
  own_bikes?: Maybe<Scalars["Boolean"]>;
};

export type Media = {
  __typename?: "Media";
  id?: Maybe<Scalars["Int"]>;
  s3key?: Maybe<Scalars["String"]>;
  metadata?: Maybe<MediaMetadata>;
  rand?: Maybe<Scalars["String"]>;
  active?: Maybe<Scalars["Boolean"]>;
  createdAt?: Maybe<Scalars["Datetime"]>;
  updatedAt?: Maybe<Scalars["Datetime"]>;
};

export type MediaMetadata = {
  __typename?: "MediaMetadata";
  filename?: Maybe<Scalars["String"]>;
  mimetype?: Maybe<Scalars["String"]>;
  about?: Maybe<Scalars["String"]>;
  uploadedBy?: Maybe<Scalars["String"]>;
  uploadComplete?: Maybe<Scalars["Boolean"]>;
  fileSize?: Maybe<Scalars["Int"]>;
  head?: Maybe<Scalars["JSON"]>;
  complete?: Maybe<Scalars["Boolean"]>;
};

export type Query = {
  __typename?: "Query";
  lessons?: Maybe<Array<Maybe<Lesson>>>;
  media?: Maybe<Array<Maybe<Media>>>;
};

export type FindMediaQueryVariables = Exact<{ [key: string]: never }>;

export type FindMediaQuery = { __typename?: "Query" } & {
  media?: Maybe<
    Array<
      Maybe<
        { __typename?: "Media" } & Pick<Media, "id" | "s3key"> & {
            metadata?: Maybe<
              { __typename?: "MediaMetadata" } & Pick<MediaMetadata, "complete">
            >;
          }
      >
    >
  >;
};

export type FindLessonsQueryVariables = Exact<{ [key: string]: never }>;

export type FindLessonsQuery = { __typename?: "Query" } & {
  lessons?: Maybe<Array<Maybe<{ __typename?: "Lesson" } & Pick<Lesson, "id">>>>;
};

export const FindMediaDocument = `
    query findMedia {
  media {
    id
    s3key
    metadata {
      complete
    }
  }
}
    `;
export const useFindMediaQuery = <TData = FindMediaQuery, TError = unknown>(
  dataSource: { endpoint: string; fetchParams?: RequestInit },
  variables?: FindMediaQueryVariables,
  options?: UseQueryOptions<FindMediaQuery, TError, TData>
) =>
  useQuery<FindMediaQuery, TError, TData>(
    ["findMedia", variables],
    fetcher<FindMediaQuery, FindMediaQueryVariables>(
      dataSource.endpoint,
      dataSource.fetchParams || {},
      FindMediaDocument,
      variables
    ),
    options
  );
export const FindLessonsDocument = `
    query findLessons {
  lessons {
    id
  }
}
    `;
export const useFindLessonsQuery = <TData = FindLessonsQuery, TError = unknown>(
  dataSource: { endpoint: string; fetchParams?: RequestInit },
  variables?: FindLessonsQueryVariables,
  options?: UseQueryOptions<FindLessonsQuery, TError, TData>
) =>
  useQuery<FindLessonsQuery, TError, TData>(
    ["findLessons", variables],
    fetcher<FindLessonsQuery, FindLessonsQueryVariables>(
      dataSource.endpoint,
      dataSource.fetchParams || {},
      FindLessonsDocument,
      variables
    ),
    options
  );
