import { useState } from "react";
import Head from "next/head";
import useUser from "../lib/useUser";
import { Layout } from "../common/Layout";

import { Main } from "../modules/home/Main";

export default function Home() {
  return (
    <Layout>
      <Main />
    </Layout>
  );
}
