import { useState, useEffect } from "react";

import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Divider,
  Paper,
  Alert,
  AlertTitle,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";

import { useFindMediaQuery } from "../../../graphql/hooks/index.tsx";

import {
  QueryClient,
  QueryClientProvider,
  useQuery,
  useMutation,
} from "react-query";
import { request, gql } from "graphql-request";

import { Imgix, Hero, Section } from "../../../common/UI";
import { Background } from "react-imgix";
// import { Menu as MenuIcon } from "@material-ui/icons";

import moment from "moment";
import ReactMarkdown from "react-markdown";
// import { useEffectOnce } from "react-use";
// import fetch from "node-fetch";

// import { Carousel } from "react-responsive-carousel";
import Carousel, { Dots } from "@brainhubeu/react-carousel";
// import { useSharedState } from "../../../lib/global_state_events";

export default function Main() {
  // const { status, data, error, isFetching } = useFindMediaQuery({
  //   endpoint: "/api/graphql",
  // });

  // console.log("DATA:", data);

  return (
    <Grid item sx={{ flex: 1 }}>
      <LandingVideoAndText />

      <SurfLessonsSection />

      <ReviewsSection />

      <Divider />
      <GearSection />
      <Divider />
      <InlineReview1 />
      <Divider />
      <SameDaySection />
      <Divider />
      <LocationsSection />
      <Divider />
      <SurfReportSection />
      <Divider />
      <BikeSection />

      {/* <OldReviewsSection />
      <Divider /> */}
    </Grid>
  );
}

const LandingVideoAndText = () => {
  return (
    <Box
      sx={{
        minHeight: "80vh",
        position: "relative",
      }}
    >
      <Box>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          sx={{
            minHeight: "80vh",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              zIndex: 10,
              color: "white",
              textShadow: "0 1px 0 black",
              textAlign: "center",
              padding: "12px",
              background: "rgba(0,0,0,0.1)",
              borderRadius: 2,
            }}
          >
            The #1 Choice for Surf Instruction in Monterey
          </Typography>
          <Typography
            variant="h4"
            sx={{
              zIndex: 10,
              color: "white",
              textShadow: "0 1px 0 black",
              textAlign: "center",
              padding: "12px",
              background: "rgba(0,0,0,0.1)",
              borderRadius: 2,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            Safety and Fun are our Top Priorities!
          </Typography>
          <br />
          <Grid container spacing={3} justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                href="/surf"
                sx={{ zIndex: 10 }}
              >
                Lessons start at $99
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="info"
                href="tel:8312648008"
                sx={{ zIndex: 10 }}
              >
                Call Us! (831) 264-8008
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "0px",
          left: "0px",
          height: "100%",
          width: "100%",
          zIndex: "4",
        }}
      >
        <Box
          sx={{
            height: 8,
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            background:
              "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 50%)",
          }}
        />
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        >
          <source
            src="https://bigsurf1.s3-us-west-1.amazonaws.com/all-output-2.mp4"
            type="video/mp4"
          />
        </video>
      </Box>
    </Box>
  );
};

const ReviewsSection = () => {
  return (
    <div style={{ background: "#fafafa" }}>
      <Container sx={{ maxWidth: 1280, padding: "24px" }}>
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <ReviewCardGoogle />
          </Grid>
          <Grid item md={4} xs={12}>
            <ReviewCardTripAdvisor />
          </Grid>
          <Grid item md={4} xs={12}>
            <ReviewCardYelp />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const SurfLessonssSession_Item = ({
  left,
  bgImg,
  title,
  subtitle1,
  subtitle2,
  link,
}) => {
  return (
    <Box
      sx={{
        background: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)), url(${bgImg}) no-repeat`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        padding: 3,
      }}
    >
      <Container
        sx={{
          maxWidth: 1280 / 2,
          marginRight: left ? 0 : "auto",
          marginLeft: left ? "auto" : 0,
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            minHeight: 200,
          }}
        >
          <Grid item>
            <Typography
              variant="h4"
              sx={{
                color: "white",
                textShadow: "0 1px 0 black",
                textAlign: "center",
              }}
            >
              <Link href={link} color="inherit" underline="hover">
                {title}
              </Link>
            </Typography>
            {subtitle1 !== undefined ? (
              <Typography
                variant="body1"
                sx={{
                  color: "white",
                  textShadow: "0 1px 0 black",
                  textAlign: "center",
                }}
              >
                {subtitle1}
              </Typography>
            ) : null}
            {subtitle2 !== undefined ? (
              <Typography
                variant="body1"
                sx={{
                  color: "white",
                  textShadow: "0 1px 0 black",
                  textAlign: "center",
                }}
              >
                {subtitle2}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const SurfLessonsSection = () => {
  return (
    <div style={{ background: "#fafafa" }}>
      {/* Private/Group */}
      <Grid container alignItems="center" spacing={0}>
        <Grid item md={6} xs={6}>
          <SurfLessonssSession_Item
            left
            title="Private Lesson"
            bgImg="https://bigsurf1public.imgix.net/public/6134a495-4e01-41cc-a94a-8a137e24da3d.jpg?w=1200"
            subtitle1="(1 person)"
            subtitle2={
              <>
                Starting at{" "}
                <span style={{ textDecoration: "line-through" }}>$199</span>{" "}
                $175
              </>
            }
            link="/surf#private"
          />
        </Grid>
        <Grid item md={6} xs={6}>
          <SurfLessonssSession_Item
            title="Private Group"
            bgImg="https://bigsurf1public.imgix.net/public/3f4838f1-49e5-4ff2-9174-82a23381ee77.jpg"
            subtitle1="(2-4 people)"
            subtitle2={
              <>
                Low as{" "}
                <span style={{ textDecoration: "line-through" }}>$125</span> $99
                / person
              </>
            }
            link="/surf#group"
          />
        </Grid>
        <Grid item md={6} xs={6}>
          <SurfLessonssSession_Item
            left
            title="Big Groups"
            bgImg="https://bigsurf1public.imgix.net/public/a9e768ef-f672-4716-9aa7-fe8009403f77.jpg"
            subtitle1="(5+ people)"
            subtitle2={
              <>
                <span style={{ textDecoration: "line-through" }}>$99</span> $90
                / person
              </>
            }
            link="/surf#large"
          />
        </Grid>
        <Grid item md={6} xs={6}>
          <SurfLessonssSession_Item
            title="Join a Group"
            bgImg="https://bigsurf1public.imgix.net/public/1d47d41a-8ec7-44c5-8d10-e02c84fda109.jpg"
            subtitle2={<>$99/person</>}
            link="/surf#join"
          />
        </Grid>
        <Grid item md={6} xs={6}>
          <SurfLessonssSession_Item
            left
            title="Corporate Parties"
            bgImg="https://bigsurf1public.imgix.net/public/3851f4c6-912a-4851-837f-38cb7cc6337b.jpg"
            subtitle2={<>Contact Us!</>}
            link="/surf#corp"
          />
        </Grid>
        <Grid item md={6} xs={6}>
          <SurfLessonssSession_Item
            title="SurFit Classes"
            bgImg="https://bigsurf1public.imgix.net/public/412c0c9d-bff1-4aaf-b622-8f7b1bc6b528.jpg"
            subtitle2={<>Contact Us!</>}
            link="/surf#surfit"
          />
        </Grid>
      </Grid>

      {/* 
	{{!-- Book Lessons (Private/Group) --}}
	<section class="full-width" id="lessons">
		<div class="section-container">
			<div class="section-one">
				<div class="section-content" {{{customImageEdit 'page.home.bg.private.image-1'}}}>
					<a class="" href="/surf-lessons">
						Private Lesson
						<br />
						<span>(1 person)</span>
						<br />
						<span style="font-size:18px;">
							{{{marked (kv 'home.lessonblocks.private')}}}
						</span>
					</a>
				</div>
				<div class="section-bg bg-type-single">
				</div>
			</div>
			<div class="section-two">
				<div class="section-content" {{{customImageEdit 'page.home.bg.group-private.image-1'}}}>
					<a class="" href="/surf-lessons#group">
						Private Group
						<br />
						<span>(2-4 people)</span>
						<br />
						<span style="font-size:18px;">
							{{{marked (kv 'home.lessonblocks.private-group')}}}
						</span>
					</a>
				</div>
				<div class="section-bg bg-type-group-private">
				</div>
			</div>
		</div>
	</section>

	{{!-- Book Lessons (Large Group/Join) --}}
	<section class="full-width">
		<div class="section-container">
			<div class="section-one">
				<div class="section-content" {{{customImageEdit 'page.home.bg.group-large.image-1'}}}>
					<a class="" href="/surf-lessons#large">
						Large Groups
						<br />
						<span>(5+)</span>
						<br />
						<span style="font-size:18px;">
							{{{marked (kv 'home.lessonblocks.large-group')}}}
						</span>
					</a>
				</div>
				<div class="section-bg section-bg-reverse bg-type-large-group">
				</div>
			</div>
			<div class="section-two">
				<div class="section-content" {{{customImageEdit 'page.home.bg.group-join.image-1'}}}>
					<a class="" href="/surf-lessons#public">
						Join a Group
						<br />
						<span style="font-size:18px;">
							{{{marked (kv 'home.lessonblocks.join-group')}}}
						</span>
					</a>
				</div>
				<div class="section-bg section-bg-reverse bg-type-group-join">
				</div>
			</div>
		</div>
	</section>


	{{!-- Book Lessons (Corp/More) --}}
	<section class="full-width">
		<div class="section-container">
			<div class="section-one">
				<div class="section-content" {{{customImageEdit 'page.home.bg.corp.image-1'}}}>
					<a class="" href="/surf-lessons#corp">
						Corporate Parties
						<br />
						<span style="font-size:18px;">
							{{{marked (kv 'home.lessonblocks.corporate')}}}
						</span>
					</a>
				</div>
				<div class="section-bg bg-type-corp">
				</div>
			</div>
			<div class="section-two">
				<div class="section-content" {{{customImageEdit 'page.home.bg.surfit.image-1'}}}>
					<a class="" href="/surf-lessons#fit">
						SurFit Classes
						<br />
						<span style="font-size:18px;">
							{{{marked (kv 'home.lessonblocks.surfit')}}}
						</span>
					</a>
				</div>
				<div class="section-bg bg-type-surfit">
				</div>
			</div>
		</div>
	</section> */}
    </div>
  );
};

const OldReviewsSection = () => {
  return (
    <div style={{ background: "#fafafa" }}>
      <Container sx={{ maxWidth: 1280 }}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item md={6}>
            <Typography variant="h3">Highly Reviewed!</Typography>
            <Typography variant="body1">
              We're really proud of our instructors and the surf experience we
              provide. Check out our reviews on various platforms to see what
              previous customers thought!
            </Typography>
            <br />
            <br />
            <br />
            <Button
              href="/instructors"
              variant="outlined"
              color="primary"
              size="large"
            >
              See our Instructors
            </Button>
            <br />
            <br />
            <div>
              Lessons start at only $80. Private, Group, and Master classes
              available.
              <br />
              We also offer mountain biking!
            </div>
          </Grid>
          <Grid item md={6}>
            <div style={{ padding: "24px 0" }}>
              <Imgix
                src={
                  "https://bigsurf1public.imgix.net/public/a9e768ef-f672-4716-9aa7-fe8009403f77.jpg"
                }
                imgixParams={{ mask: "corners", "corner-radius": 40 }}
                htmlAttributes={{ style: { width: "100%" } }}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const GearSection = () => {
  return (
    <Box
      sx={{
        minHeight: 400,
        background: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)), url(https://bigsurf1.imgix.net/equipment.jpg?w=1200) no-repeat`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        padding: 3,
      }}
    >
      <Container>
        <Box
          sx={{
            padding: "12px",
            background: "rgba(0,0,0,0.1)",
            borderRadius: 2,
          }}
        >
          <Typography
            variant="h2"
            sx={{
              color: "white",
              textShadow: "0 1px 0 black",
            }}
          >
            Equipment Partners
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: "white",
              textShadow: "0 1px 0 black",
            }}
          >
            High Quality Wetsuits, Booties, and more for the Ultimate Experience
          </Typography>
        </Box>
        <br />
        <Grid
          container
          spacing={3}
          columns={{ md: 10, xs: 10 }}
          justifyContent="center"
        >
          <Grid item md={2} xs={3}>
            <EquipmentCard imgSrc="https://bigsurf1.imgix.net/oneil.png" />
          </Grid>
          <Grid item md={2} xs={3}>
            <EquipmentCard imgSrc="https://bigsurf1.imgix.net/hyperflex.png" />
          </Grid>
          <Grid item md={2} xs={3}>
            <EquipmentCard imgSrc="https://bigsurf1.imgix.net/hotline.gif" />
          </Grid>
          <Grid item md={2} xs={3}>
            <EquipmentCard imgSrc="https://bigsurf1.imgix.net/Zinka-logo.png" />
          </Grid>
          <Grid item md={2} xs={3}>
            <EquipmentCard imgSrc="https://bigsurf1.imgix.net/catch-surf-logo.jpeg" />
          </Grid>
          <Grid item md={2} xs={3}>
            <EquipmentCard imgSrc="https://bigsurf1.imgix.net/NSP-Logo.jpg" />
          </Grid>
          <Grid item md={2} xs={3}>
            <EquipmentCard imgSrc="https://bigsurf1.imgix.net/ocean-and-earth-logo.jpg" />
          </Grid>
          <Grid item md={2} xs={3}>
            <EquipmentCard imgSrc="https://bigsurf1.imgix.net/odysea-logo.jpg" />
          </Grid>
          <Grid item md={2} xs={3}>
            <EquipmentCard imgSrc="https://bigsurf1.imgix.net/surftech-logo.png" />
          </Grid>
          <Grid item md={2} xs={3}>
            <EquipmentCard imgSrc="https://bigsurf1.imgix.net/storm-blade-logo.jpg" />
          </Grid>
        </Grid>
        <br />
        <Box
          sx={{
            padding: "12px",
            // background: "rgba(0,0,0,0.1)",
            // background: "white",
            // borderRadius: 2,
            textAlign: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              // color: "white",
              // textShadow: "0 1px 0 black",
              padding: "12px 24px",
              background: "white",
              borderRadius: 2,
              display: "inline",
            }}
          >
            <Link href={"/gear"} underline="hover">
              Visit our Gear / Rentals page!
            </Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

const InlineReview1 = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      sx={{ minHeight: 300 }}
    >
      <Box sx={{}}>
        <Container sx={{ padding: { xs: "24px" } }}>
          <Typography
            variant="h4"
            sx={{
              textAlign2: "center",
            }}
          >
            "Extremely energetic and knowledgeable"
          </Typography>
        </Container>
      </Box>
      <Box sx={{}}>
        <Container>
          <Typography
            variant="h6"
            sx={{
              textAlign2: "center",
            }}
          >
            Had a 1on1 with Zane and it was awesome. He is extremely energetic
            and knowledgeable and made it a very fun experience to learn to
            surf. He definitely is able to tailor the lesson depending on the
            person and gave me great tips for finding future spots. Highly
            recommend
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textAlign2: "center",
            }}
          >
            - Dylan D., January 2019
          </Typography>
        </Container>
      </Box>
    </Grid>
  );
};

const SameDaySection = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      // direction="column"
      sx={{ minHeight: 300, padding: { xs: "24px" } }}
    >
      <Grid container columnSpacing={3}>
        <Grid item sx={{ flex: { sm: "0 1 auto", md: 1 } }}>
          <Box sx={{}}>
            <Container>
              <Typography
                variant="h4"
                sx={{
                  textAlign: "center",
                }}
              >
                Same-Day Lessons Available!
              </Typography>
              <br />
            </Container>
          </Box>
          <Box sx={{}}>
            <Container>
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                }}
              >
                Check for the latest available surf times (at the beaches with
                the best waves!), and book a last-minute lesson today!
              </Typography>
              <br />
              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                }}
              >
                <Button href="/book/create" variant="contained" color="info">
                  Reserve Today!
                </Button>
              </Typography>
            </Container>
          </Box>
        </Grid>
        <Divider
          flexItem
          orientation="vertical"
          sx={{ display: { sm: "none", md: "block" } }}
        />
        {/* <Grid item sx={{ flex: 1 }}> */}
        <Divider
          // flexItem
          // orientation="vertical"
          sx={{ flex: 1, mt: 3, mb: 3, display: { sm: "block", md: "none" } }}
        />
        {/* </Grid> */}
        <Grid item sx={{ flex: { sm: "0 1 auto", md: 1 } }}>
          <Box sx={{}}>
            <Container>
              <Typography
                variant="h4"
                sx={{
                  textAlign: "center",
                }}
              >
                Group Activity Calendar
              </Typography>
              <br />
            </Container>
          </Box>
          <Box sx={{}}>
            <Container>
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                }}
              >
                See when Join-a-Group and SurFit classes are currently
                scheduled.
              </Typography>
              <br />
              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                }}
              >
                <Button href="/calendar" variant="contained" color="info">
                  View Calendar
                </Button>
              </Typography>
            </Container>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

const SurfReportSection = () => {
  const {
    isLoading,
    error,
    data: surfReports = [],
    refetch,
  } = useQuerySurfReports({
    date: moment().format("YYYY-MM-DD"),
  });
  const surf_report = surfReports?.[0];

  if (!surf_report || !surf_report?.text?.length) {
    return null;
  }

  return (
    <div style={{ background: "#eaf6ff", maxWidth: "100vw" }}>
      <Container sx={{ maxWidth: 1280, padding: { xs: "24px" } }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item md={6}>
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              Today's Surf Report:
            </Typography>
            <Typography variant="body1" sx={{ textAlign: "center" }}>
              ({moment().format("dddd, MMMM Do")})
            </Typography>
          </Grid>
          <Grid item md={6} zeroMinWidth>
            <div>
              <ReactMarkdown
                children={surf_report.text}
                // remarkPlugins={[remarkGfm]}
              />
            </div>

            {/* <Carousel
              showArrows
              showThumbs
              dynamicHeight
              //   autoPlay
              stopOnHover
              swipeable
              infiniteLoop
              width={"200px"}
            >
              <div>
                <img src="https://bigsurf1.imgix.net/carmel1.jpg?fit=crop&ar=16:9" />
                <p className="legend">Carmel Beach 1</p>
              </div>
              <div>
                <img src="https://bigsurf1.imgix.net/carmel2.jpg?fit=crop&ar=16:9" />
                <p className="legend">Carmel Beach 2</p>
              </div>
              <div>
                <img src="https://bigsurf1public.imgix.net/public/1d2b89d9-9611-4325-b245-9cfa9a960d6d.jpg?fit=crop&ar=16:9" />
                <p className="legend">Asilomar Beach 1</p>
              </div>
              <div>
                <img src="https://bigsurf1public.imgix.net/public/c7d7d07b-d2da-49b3-9924-97e24caa9508.jpg?fit=crop&ar=16:9" />
                <p className="legend">Asilomar Beach 2</p>
              </div>
              <div>
                <img src="https://bigsurf1.imgix.net/loverspoint1.jpg?fit=crop&ar=16:9" />
                <p className="legend">Lover's Point 1</p>
              </div>
              <div>
                <img src="https://bigsurf1.imgix.net/loverspoint2.jpg?fit=crop&ar=16:9" />
                <p className="legend">Lover's Point 2</p>
              </div>
              <div>
                <img src="https://bigsurf1.imgix.net/loverspoint3.jpg?fit=crop&ar=16:9" />
                <p className="legend">Lover's Point 3</p>
              </div>
              <div>
                <img src="https://bigsurf1public.imgix.net/public/733926c8-542b-44ca-ac78-fda6b334b2ce.jpg?fit=crop&ar=16:9" />
                <p className="legend">Monterey Beach 1</p>
              </div>
              <div>
                <img src="https://bigsurf1public.imgix.net/public/a9f660fb-bfd3-42c6-8956-e13f92541178.jpg?fit=crop&ar=16:9" />
                <p className="legend"> Old Fisherman's Wharf 1</p>
              </div>
              <div>
                <img src="https://bigsurf1public.imgix.net/public/28ed321c-8395-413d-bf42-6641822996e5.jpg?fit=crop&ar=16:9" />
                <p className="legend"> Old Fisherman's Wharf 2</p>
              </div>
            </Carousel> */}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const LocationsSection = () => {
  return (
    <div style={{ background: "#fafafa", maxWidth: "100vw" }}>
      <Container sx={{ maxWidth: 1280, padding: { xs: "24px" } }}>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Typography variant="h4" sx={{}}>
              The Best Beaches in and around Monterey
            </Typography>
            <br />
            <Typography variant="h6" sx={{}}>
              All the info about the best surf beaches, and meetup spots for
              lessons.
            </Typography>
            <br />
            <Button
              variant="contained"
              color="primary"
              href="/locations"
              sx={{ zIndex: 10 }}
            >
              Check Out Locations
            </Button>
          </Grid>
          <Grid item md={6} zeroMinWidth>
            <div>
              <ImgCarousel
                imgsAndParams={[
                  {
                    src: "https://bigsurf1.imgix.net/carmel1.jpg",
                    imgixParams: { fit: "crop", ar: "16:9" },
                  },
                  {
                    src: "https://bigsurf1.imgix.net/carmel2.jpg",
                    imgixParams: { fit: "crop", ar: "16:9" },
                  },
                  {
                    src: "https://bigsurf1public.imgix.net/public/1d2b89d9-9611-4325-b245-9cfa9a960d6d.jpg",
                    imgixParams: { fit: "crop", ar: "16:9" },
                  },
                  {
                    src: "https://bigsurf1public.imgix.net/public/c7d7d07b-d2da-49b3-9924-97e24caa9508.jpg",
                    imgixParams: { fit: "crop", ar: "16:9" },
                  },
                  {
                    src: "https://bigsurf1.imgix.net/loverspoint1.jpg",
                    imgixParams: { fit: "crop", ar: "16:9" },
                  },
                  {
                    src: "https://bigsurf1.imgix.net/loverspoint2.jpg",
                    imgixParams: { fit: "crop", ar: "16:9" },
                  },
                  {
                    src: "https://bigsurf1.imgix.net/loverspoint3.jpg",
                    imgixParams: { fit: "crop", ar: "16:9" },
                  },
                  {
                    src: "https://bigsurf1public.imgix.net/public/733926c8-542b-44ca-ac78-fda6b334b2ce.jpg",
                    imgixParams: { fit: "crop", ar: "16:9" },
                  },
                  {
                    src: "https://bigsurf1public.imgix.net/public/a9f660fb-bfd3-42c6-8956-e13f92541178.jpg",
                    imgixParams: { fit: "crop", ar: "16:9" },
                  },
                  {
                    src: "https://bigsurf1public.imgix.net/public/28ed321c-8395-413d-bf42-6641822996e5.jpg",
                    imgixParams: { fit: "crop", ar: "16:9" },
                  },
                ]}
              />
            </div>

            {/* <Carousel
              showArrows
              showThumbs
              dynamicHeight
              //   autoPlay
              stopOnHover
              swipeable
              infiniteLoop
              width={"200px"}
            >
              <div>
                <img src="https://bigsurf1.imgix.net/carmel1.jpg?fit=crop&ar=16:9" />
                <p className="legend">Carmel Beach 1</p>
              </div>
              <div>
                <img src="https://bigsurf1.imgix.net/carmel2.jpg?fit=crop&ar=16:9" />
                <p className="legend">Carmel Beach 2</p>
              </div>
              <div>
                <img src="https://bigsurf1public.imgix.net/public/1d2b89d9-9611-4325-b245-9cfa9a960d6d.jpg?fit=crop&ar=16:9" />
                <p className="legend">Asilomar Beach 1</p>
              </div>
              <div>
                <img src="https://bigsurf1public.imgix.net/public/c7d7d07b-d2da-49b3-9924-97e24caa9508.jpg?fit=crop&ar=16:9" />
                <p className="legend">Asilomar Beach 2</p>
              </div>
              <div>
                <img src="https://bigsurf1.imgix.net/loverspoint1.jpg?fit=crop&ar=16:9" />
                <p className="legend">Lover's Point 1</p>
              </div>
              <div>
                <img src="https://bigsurf1.imgix.net/loverspoint2.jpg?fit=crop&ar=16:9" />
                <p className="legend">Lover's Point 2</p>
              </div>
              <div>
                <img src="https://bigsurf1.imgix.net/loverspoint3.jpg?fit=crop&ar=16:9" />
                <p className="legend">Lover's Point 3</p>
              </div>
              <div>
                <img src="https://bigsurf1public.imgix.net/public/733926c8-542b-44ca-ac78-fda6b334b2ce.jpg?fit=crop&ar=16:9" />
                <p className="legend">Monterey Beach 1</p>
              </div>
              <div>
                <img src="https://bigsurf1public.imgix.net/public/a9f660fb-bfd3-42c6-8956-e13f92541178.jpg?fit=crop&ar=16:9" />
                <p className="legend"> Old Fisherman's Wharf 1</p>
              </div>
              <div>
                <img src="https://bigsurf1public.imgix.net/public/28ed321c-8395-413d-bf42-6641822996e5.jpg?fit=crop&ar=16:9" />
                <p className="legend"> Old Fisherman's Wharf 2</p>
              </div>
            </Carousel> */}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const BikeSection = () => {
  return (
    <Section id="surfit">
      <Container sx={{ padding: { xs: "24px" } }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item md={6}>
            <Typography variant="h3">
              We also offer Bike Adventures!
              {/* <span class="is-pulled-right is-size-7">
                    <Link href="#menu">top</Link>
                  </span> */}
            </Typography>
            <p>
              Come explore the interior of the bay with our exciting and
              beautiful biking adventures. We offer both guides and rentals, and
              can even meet you at the trailhead!
            </p>
            <p>
              <Button variant="outlined" href="/bike-adventures">
                More Info on Bike Adventures
              </Button>
            </p>
          </Grid>
          <Grid item md={6}>
            <div>
              <video
                autoPlay
                loop
                muted
                playsInline
                controls
                style={{ width: "100%" }}
              >
                <source
                  src="https://bigsurf1.s3-us-west-1.amazonaws.com/bike-home-480.m4v"
                  type="video/mp4"
                />
              </video>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

const EquipmentCard = ({ imgSrc }) => {
  return (
    <Paper
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={imgSrc}
        style={{ width: "90%", maxHeight: "100%", verticalAlign: "center" }}
      />
    </Paper>
  );
};

const ReviewCard = ({ link, title, reviewTitle, reviewDate, reviewText }) => {
  return (
    <Card>
      <CardHeader
        disableTypography
        title={
          <Typography variant="h6">
            <Link
              // color="inherit"
              // underline="hover"
              href={link}
              target="_blank"
            >
              {title}
            </Link>
          </Typography>
        }
      />
      <Divider />
      <CardContent>
        <Typography variant="h5">"{reviewTitle}"</Typography>
        <div>
          <Typography variant="caption">{reviewDate}</Typography>
        </div>
        <Typography variant="p">{reviewText}</Typography>
      </CardContent>
    </Card>
  );
};

const ReviewCardGoogle = () => {
  return (
    <ReviewCard
      link="https://www.google.com/maps/place/Big+Surf+Lessons+%26+Adventures/@36.3881864,-121.8181792,10z/data=!4m7!3m6!1s0x0:0xd4d284b33e9421f0!8m2!3d36.6156673!4d-121.9360331!9m1!1b1"
      title={
        <>
          <strong>&nbsp;4.9&nbsp;</strong> on Google (60+ reviews)
        </>
      }
      reviewTitle="My kids loved it"
      reviewDate="August 2021"
      reviewText={
        <>
          ...Sam adjusted her lesson to them perfectly. She was super patient
          and encouraged and applauded their achievement each time...{" "}
          <a href="https://goo.gl/maps/FjVCvbp5C6DHkL1k8" target="blank">
            See entire review
          </a>
        </>
      }
    />
  );
};

const ReviewCardTripAdvisor = () => {
  return (
    <ReviewCard
      link="https://www.tripadvisor.com/Attraction_Review-g32840-d18688953-Reviews-Big_Surf_Adventures-Pacific_Grove_Monterey_County_California.html"
      title={
        <>
          <strong>&nbsp;5 Stars&nbsp;</strong> on TripAdvisor (25+ reviews)
        </>
      }
      reviewTitle="Great Service, Great Time"
      reviewDate="August 2021"
      reviewText={
        <>
          What a great experience. We took our 11 year old grandson out with
          Abraham today and we couldn’t be happier...{" "}
          <a
            href="https://www.tripadvisor.com/ShowUserReviews-g32840-d18688953-r802840019-Big_Surf_Adventures-Pacific_Grove_Monterey_County_California.html?m=19905"
            target="blank"
          >
            See entire review
          </a>
        </>
      }
    />
  );
};

const ReviewCardYelp = () => {
  return (
    <ReviewCard
      link="https://www.yelp.com/biz/big-surf-adventures-pacific-grove"
      title={
        <>
          <strong>&nbsp;5 Stars&nbsp;</strong> on Yelp (10+ reviews)
        </>
      }
      reviewTitle="100x a better surfer after my lesson"
      reviewDate="July 2021"
      reviewText={
        <>
          Zane was very communicative and responded quickly to questions we
          had...They were wonderful!{" "}
          <a
            href="https://www.yelp.com/biz/big-surf-adventures-pacific-grove?hrid=Al4iKyOrV0RD_11fo_Lf5A&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)"
            target="blank"
          >
            See entire review
          </a>
        </>
      }
    />
  );
};

const ImgCarousel = ({ imgsAndParams }) => {
  const [val, setVal] = useState(0);

  if (typeof window === "undefined") {
    return null;
  }

  // params for ImgiximgsAndParams
  const slides = imgsAndParams.map((img) => {
    return (
      <Imgix
        src={img.src}
        imgixParams={img.imgixParams}
        htmlAttributes={{ style: { width: "100%" } }}
      />
    );
  });
  const thumbnails = imgsAndParams.map((img) => {
    return (
      <Imgix
        src={img.src}
        imgixParams={img.imgixParams}
        htmlAttributes={{ style: { width: "100%" } }}
      />
    );
  });

  return (
    <div>
      <Carousel value={val} slides={slides} onChange={setVal} />
      <Dots
        number={thumbnails.length}
        thumbnails={thumbnails}
        value={val}
        onChange={setVal}
      />
    </div>
  );
};

const useQuerySurfReports = ({ date }) => {
  // All the dates I can see
  // - for admin or pro
  return useQuery(["surfReports", "filtered"], async () => {
    const result = await request(
      "/api/graphql",
      gql`
        query ($body: JSON) {
          surfReports(body: $body) {
            id
            date
            metadata
            text
          }
        }
      `,
      {
        body: {
          date,
        },
      }
    );
    // console.log("RESULT:", result);
    return result.surfReports;
  });
};
